@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap");

:root {
  --color-primary: #262626;
  --color-secondary: #262626;
  --color-ternary: #424242;
}

body {
  margin: 0;
  font-family: -apple-system, "Rubik", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px !important;
}

input[type="text"] {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  *:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

button {
  --tw-ring-shadow: 0 0 #000 !important;
}

.e-marker {
  transform-origin: bottom center;
  cursor: pointer;
}

.e-marker__marker {
  fill: rgba(0, 0, 0, 0.411);
}

.e-marker__icon {
  fill: #fff;
  transform-origin: 50% 50%;
}

/* .e-marker__image { */
/* transform: scale(0); */
/* transform-origin: 50% 50%; */
/* opacity: 0; */
/* } */

.e-marker__circle {
  display: none;
  fill: #ec008c;
  opacity: 0.5;
}

.e-marker__loader {
  fill: #fff;
  transform-origin: 50% 50%;
  transform: scale(0);
}

.e-marker__text {
  font-size: 12px;
  font-family: brandon-grotesque, sans-serif;
  text-align: center;
  opacity: 0;
  color: #fff;
  fill: #fff;
  transform-origin: 50% 50%;
  text-anchor: middle;
}

.bg-primary {
  background-color: var(--color-primary);
  color: theme("colors.gray.100");
}
.bg-secondary-mid {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}
.bg-primary-light {
  background-color: theme("colors.white");
  color: var(--color-primary);
}
.bg-secondary {
  background-color: white;
  color: var(--color-primary);
}
.text-primary {
  color: var(--color-secondary);
}
.text-secondary {
  color: var(--color-primary);
}
.text-primary-light {
  color: theme("colors.white");
}
.text-primary-semi-light {
  color: theme("colors.gray.400");
}

.bg-gradient-button {
  background-image: linear-gradient(
    140deg,
    var(--color-secondary),
    var(--color-primary)
  );
}
.bg-gradient-button-light {
  background-image: linear-gradient(
    140deg,
    var(--color-primary),
    var(--color-secondary)
  );
  border: 2px solid #777;
  color: theme("colors.gray.200");
}
.bg-gradient-navbar {
  background-image: linear-gradient(
    150deg,
    var(--color-secondary),
    var(--color-primary)
  );
  color: theme("colors.gray.100");
}
.bg-gradient-primary {
  background-image: linear-gradient(
    140deg,
    var(--color-primary),
    var(--color-secondary)
  );
  color: theme("colors.gray.100");
}
.bg-gradient-secondary {
  background-image: linear-gradient(
    140deg,
    var(--color-primary),
    var(--color-secondary) 70%
  );
  color: theme("colors.gray.100");
}
.bg-add-twib-button {
  background: var(--color-primary);
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
}
.bg-gradient-even {
  background-image: linear-gradient(
    140deg,
    var(--color-primary),
    var(--color-secondary)
  );
  color: theme("colors.gray.100");
}

.bg-gradient-permissions {
    background-image: linear-gradient(
        140deg,
        var(--color-primary),
        var(--color-ternary)
    );
    color: theme("colors.gray.100");
}

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}